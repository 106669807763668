<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="text"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  metaInfo: {
    title: 'PrivacyPolicy',
    link: []
  },
  data () {
    return {
      text: `
      Technical Support
      <br>
        If you have any questions, please contact us: 

        <a
        href="mailto:finge7164@gmail.com"
        target="_blank"
        class="url"
        style="
        color: #990000;
        text-decoration: none;
        "
        >finge7164@gmail.com</a>
        <br>
        Thanks for support
        <br>
                    `,
      project: ' Netboom Game +'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || ' Netboom Game +'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #F0F0F0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
